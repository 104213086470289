<template>
  <div class="personal-account__reviews personal-reviews">
    <h2 class="personal-bonus__title second-title">{{ $t("bonuses.my") }}</h2>
    <div class="personal-bonus__body">
      <div class="personal-bonus__body-card">
        {{ $t("bonuses.nakop", { value: bonus }) }}
        <img :src="require('@/assets/images/bonus.png')" />
      </div>

      <div class="personal-bonus__body__history">
        <h2 class="second-title" @click="bonusTree = !bonusTree">
          {{ $t("bonuses.history") }}
          <img
            :src="require('@/assets/images/up.png')"
            :class="{ rotate: bonusTree }"
          />
        </h2>
        <template v-if="bonusTree">
          <template v-for="item in bonusHistory" :key="item">
            <div
              v-if="item.title !== null"
              class="personal-bonus__body__history-item"
            >
              <p>
                {{ item.title }}
              </p>
              <span>{{ item.created_at }}</span>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    bonusTree: true,
  }),
  props: {
    bonus: {
      type: Number,
    },
    bonusHistory: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.second-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}
.rotate {
  transform: rotate(180deg) !important;
}
.personal-bonus {
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 22px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 62px 0;
    }
  }
  &__item {
    &:not(:last-child) {
      margin: 0 0 68px 0;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 60px;
    &-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px 40px 30px 40px;
      gap: 30px;
      border: 1px solid #c2c2c2;
      border-radius: 10px;
      font-weight: 500;
      font-size: 18px;
      color: #1ABBE8;
    }
    &__history {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
      &-item {
        padding: 15px 43px 15px 16px;
        display: flex;
        flex-direction: column;
        align-items: right;
        justify-content: right;
        gap: 20px;
        border: 1px solid #e3e3e3;
        border-radius: 20px;
        span {
          text-align: right;
          font-weight: 400;
          font-size: 12px;
          color: #777777;
        }
      }
    }
  }
}
</style>